const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://u52w47qq20.execute-api.us-west-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://s57uln6413.execute-api.us-west-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.9.3',
    HOSTNAME: 'https://teams-service.staging.emeco.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.emeco.forwoodsafety.com',
    WEBSOCKET: 'wss://omibhrg5zl.execute-api.us-west-2.amazonaws.com/staging'
  },
};

export default config;
